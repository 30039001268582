(function () {
    angular.module('informaApp')
        .service('TrendsViewMapper', TrendsViewMapper);

    function TrendsViewMapper($q, ChartMapper) {
        return {
            map: (source, range, hiddenDrugs) => {
                const promisesGetChartData = groupByGroupIndex(source)
                    .map(x => getChartData(x, range, hiddenDrugs))
                    .value();

                return $q.all([].concat.apply([], promisesGetChartData)).then((result) => {
                    const mappedData = result.map((x) => mapChartData(ChartMapper, x));

                    const concatedData = [].concat.apply([], mappedData);
                    concatedData.groups = concatedData.sort(sortSource);

                    return concatedData;
                });
            },
            invertData: (source) => {
                const sourceCopy = _.cloneDeep(source);
                const newGroups = [];

                for (let i = 0; i < sourceCopy.groups.length; i++) {
                    for (let j = 0; j < sourceCopy.groups[i].data.length; j++) {
                        const item = sourceCopy.groups[i].data[j];
                        const itemXValue = item.x;

                        const itemFromNewGroups = newGroups.find(function (y) {
                            return y.name === itemXValue;
                        });

                        const newDataItem = _.merge(item, {x: sourceCopy.groups[i].name});

                        if (itemFromNewGroups) {
                            itemFromNewGroups.data.push(newDataItem);
                        } else {
                            newGroups.push({name: itemXValue, data: [newDataItem]});
                        }
                    }
                }

                return {
                    ...sourceCopy,
                    groups: newGroups
                };
            }
        }
    }

    function mapChartData(ChartMapper, item) {
        const idNamePairs = item.selectedItems.map((x) => {
            return {
                id: x.id,
                name: x.name
            };
        });

        return ChartMapper.map(item.data, idNamePairs);
    }

    function getChartData([,source], range, hiddenDrugs) {
        const group = source[0].group;

        return groupByObject(source, x => x.filter)
            .map(x => makeChartFromGroup(x, group, range, hiddenDrugs));
    }

    function makeChartFromGroup([params, source], group, range, hiddenDrugs) {
        params.range = range;
        params.hiddendrugs = hiddenDrugs;

        const newFilter = group.changeFilterForCharts(params, source);

        return group.makeChart(newFilter).then((response) => {
            return {
                data: response.data.data,
                selectedItems: source
            }
        });
    }

    function groupByGroupIndex(source) {
        return _.chain(source)
            .groupBy(x => x.groupIndex)
            .toPairs();
    }

    function groupByObject(source, getValue) {
        const result = [];

        _.forEach(source, function(item) {
            const [,source] = _.find(result, ([group]) => group === getValue(item)) || [];

            if (source) {
                source.push(item);
            } else {
                result.push([getValue(item), [item]]);
            }
        });

        return result;
    }

    function sortSource (a, b) {
        if (a.label === b.label) {
            return 0;
        }

        return a.label > b.label ? 1 : -1;
    }
})();
